// import React from "react";
import { Button, Form, Input, notification } from "antd";
import { useNavigate, useLocation, Link } from "react-router-dom";

// import ArrowBackIcon from "../../../assets/svg-icon/arrowBack";
import icon_phone from "../../../assets/icons/icon_phone.png";
import icon_password from "../../../assets/icons/icon_password.png";

import { validatePhoneNumber } from "../../../utils/validate";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { additionUserData } from "../../../redux/Slices/userSlice";
import { setRole } from "../../../redux/Slices/AuthSlice";
import icon_back from "../../../assets/icons/icon_back.png";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import { useEffect } from "react";
import { COMPANY_PHONE } from "../../../utils/common";

export default function CreatePassByAuth() {
  const dispatch = useAppDispatch();
  const [api, showPopup] = notification.useNotification();
  const navigate = useNavigate();
  const loading = useAppSelector((state: any) => state.user.loadingAdditionUserData);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const role = queryParams.get("role");
  const { t, i18n } = useTranslation(["auth", "common"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const onSubmit = (value: { telephone?: string; password: string }) => {
    const data = {
      telephone: value.telephone,
      password: value.password,
    };
    try {
      dispatch(additionUserData(data)).then((resultAction: any) => {
        const payload = resultAction.payload as { message: string };
        if (additionUserData.fulfilled.match(resultAction)) {
          alertSuccess(payload.message || "Cập nhật thông tin thành công");
          setTimeout(() => {
            if (role === "user") {
              navigate("/user/home");
              dispatch(setRole({ role: "user" }));
            } else {
              navigate("/partner/register_service");
              dispatch(setRole({ role: "partner" }));
            }
          }, 1000);
        } else if (additionUserData.rejected.match(resultAction)) {
          alertFail(payload.message || "Cập nhật thất bại, vui lòng thử lại!");
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  return (
    <div className="wrapper create-password">
      {showPopup}
      <div className="header">
        <Link to="#" onClick={() => navigate(-1)}>
          <button className="header-back-button">
            <div className="header-back-icon">
              <img src={icon_back} alt="" />
            </div>
          </button>
        </Link>
        <span className="header-text">{t("tao_mat_khau")}</span>
        <a href={`tel:${COMPANY_PHONE}`} className="header-call">
          <img src={icon_phone} alt="" />
        </a>
      </div>
      <div className="create-password content-container">
        <Form onFinish={onSubmit} autoComplete="off" layout="vertical" className="create-password-form">
          <Form.Item
            label={
              <div className="create-password-label">
                <div className="create-password-label-icon">
                  <img src={icon_phone} alt="" />
                </div>
                <p className="create-password-label-text">{t("so_dien_thoai")}</p>
              </div>
            }
            name="telephone"
            rules={[
              { required: true, message: t("common:yeu_cau_khong_de_trong") },
              { validator: validatePhoneNumber, message: t("so_dien_thoai_khong_hop_le") },
            ]}
            className="create-password-item"
          >
            <Input placeholder={t("so_dien_thoai")} className="create-password-input" />
          </Form.Item>
          <Form.Item
            label={
              <div className="create-password-label">
                <div className="create-password-label-icon">
                  <img src={icon_password} alt="" />
                </div>
                <p className="create-password-label-text">{t("mat_khau")}</p>
              </div>
            }
            name="password"
            rules={[{ required: true, message: t("common:yeu_cau_khong_de_trong") }]}
            className="create-password-item"
          >
            <Input.Password placeholder={t("mat_khau")} className="create-password-input" />
          </Form.Item>
          <Form.Item
            label={
              <div className="create-password-label">
                <div className="create-password-label-icon">
                  <img src={icon_password} alt="" />
                </div>
                <p className="create-password-label-text">{t("nhap_lai_mat_khau")}</p>
              </div>
            }
            name="confirm_password"
            rules={[
              { required: true, message: t("common:yeu_cau_khong_de_trong") },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("mat_khau_khong_khop")));
                },
              }),
            ]}
            className="create-password-item"
          >
            <Input.Password placeholder={t("nhap_lai_mat_khau")} className="create-password-input" />
          </Form.Item>
          <Button type="primary" className="create-password-button primary" htmlType="submit" loading={loading}>
            {t("xac_nhan")}
          </Button>
        </Form>
      </div>
    </div>
  );
}
