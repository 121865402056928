import HeaderSevice from "../../../../components/HeaderService";
import img_logo__full from "../../../../assets/images/image_logo_full.png";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../../helper/localStorage";
import { useEffect } from "react";
import { formatPrice } from "../../../../utils/func";

const Rule = () => {
  const { t, i18n } = useTranslation(["wallet"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);
  return (
    <div className="wrapper rule">
      <HeaderSevice text={t("quy_dinh_nhan_tien")} />
      <div className="rule content-container">
        <div className="logo">
          <img src={img_logo__full} alt="" />
        </div>
        <h1 className="title">CÁC ĐIỀU KHOẢN CỦA THOẢ THUẬN DỊCH VỤ</h1>
        <div className="text">
          <ul>
            <li>Tiền sẽ được trả vào ngày 1 hàng tháng</li>
            <li>
              Số tiền tối thiểu để được nhận là <span>{formatPrice(2000000)}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Rule;
