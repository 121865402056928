import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Modal } from "antd";

import config from "../../../config";
import { TitleSetting } from "../../../types/common";
import { getLocalStorage } from "../../../helper/localStorage";

import overLoader from "../../../components/overLoader";
import icon_chevron_right from "../../../assets/icons/icon_chevron_right.png";
// import icon_back from "../../../assets/icons/icon_back.png";
import { useTranslation } from "react-i18next";
import HeaderSevice from "../../../components/HeaderService";

export const SettingUser = () => {
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["setting", "auth", "common"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const listTitleSetting: TitleSetting[] = [
    {
      title: t("chi_tiet_tai_khoan"),
      path: config.myAccountUser,
    },
    {
      title: t("auth:doi_mat_khau"),
      path: config.changePasswordUser,
    },
    {
      title: t("doi_ngon_ngu"),
      path: config.changeLanguageUser,
    },
    {
      title: t("dieu_khoan_su_dung"),
      path: config.termsUser,
    },
    {
      title: t("chinh_sach_bao_mat"),
      path: config.privacyPolicyUser,
    },
  ];

  const handleLogout = async () => {
    setStatusLoader(true);
    //   removeLocalStorage("access_token");
    localStorage.clear();
    setTimeout(() => {
      setStatusLoader(false);
      navigate("/user/login");
    }, 1000);
  };

  const handleShowModal = () => {
    setOpen(true);
  };

  return (
    <div className="wrapper setting">
      {statusLoader ? overLoader() : ""}
      {/* {showPopup} */}

      {/* modal */}
      <Modal
        title={t("auth:ban_co_muon_dang_xuat")}
        open={open}
        onOk={handleLogout}
        onCancel={() => setOpen(false)}
        centered
        okText={t("common:dong_y")}
        cancelText={t("common:thoat")}
        okType="primary"
        okButtonProps={{ danger: true }}
      >
        {/* <p>Bạn có muốn đăng xuất ?</p> */}
      </Modal>

      <HeaderSevice text={t("cai_dat")} />
      <div className="setting-list content-container">
        {listTitleSetting?.map((val: TitleSetting, index: number) => (
          <Link to={val.path} className="setting-item" key={index}>
            <span className="setting-item-text">{val.title}</span>
            <div className="setting-item-icon">
              <img src={icon_chevron_right} alt="" />
            </div>
          </Link>
        ))}
        <span className="setting-item" onClick={handleShowModal}>
          <span className="setting-item-text">{t("dang_xuat")}</span>
          <div className="setting-item-icon">
            <img src={icon_chevron_right} alt="" />
          </div>
        </span>
      </div>
    </div>
  );
};
