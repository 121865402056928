import { Link } from "react-router-dom";
import { Select, Button, AutoComplete, Input } from "antd";
import icon_search from "../../../../assets/icons/icon_search.png";
// import icon_back from "../../../../assets/icons/icon_back.png";
import LoadingParagraph from "../../../../components/skeleton";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { useEffect, useState } from "react";
import { getAllBank } from "../../../../redux/Slices/serviceSlice";
import local, { getLocalStorage } from "../../../../helper/localStorage";
import { useTranslation } from "react-i18next";
import HeaderSevice from "../../../../components/HeaderService";

interface Branch {
  ID: number;
  MaChiNhanh: string;
  MaTinh: string | null;
  Loai: string | null;
  TenChiNhanh: string;
}

interface Province {
  Code: string;
  TenTinhThanh: string;
  branch: Branch[];
}

interface Bank {
  ID: number;
  MaNganHang: string;
  TenNH: string;
  Loai: string | null;
  province: Province[];
}

interface BankData {
  ID: number;
  Loai: string;
  TenLoaiNganHang: string;
  bankList: Bank[];
}

const SelectBank = () => {
  //   const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const allBankData: BankData[] = useAppSelector((state) => state.service.allBankData);
  const loadingBankData = useAppSelector((state) => state.service.loadingBankData);

  const [selectedState, setSelectedState] = useState<Array<boolean>>([false, false, false, false]);
  const [selectedBankType, setSelectedBankType] = useState<string | null>(null);
  const [selectedBank, setSelectedBank] = useState<string | null>(null);
  const [selectedProvince, setSelectedProvince] = useState<string | null>(null);
  //   const [selectedBranch, setSelectedBranch] = useState<string | null>(null);
  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const allBanks: Bank[] = allBankData.flatMap((bankData) => bankData.bankList);
  //   const data_bank = local.StorageGetSecure("data_bank");
  const { t, i18n } = useTranslation(["wallet"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    dispatch(getAllBank());
  }, [dispatch]);

  const onChange = (data: string) => {
    setValue(data);
    const filteredOptions = allBanks
      .filter((bank) => bank.TenNH.toLowerCase().includes(data.toLowerCase()))
      .map((bank) => ({ value: bank.TenNH }));
    setOptions(filteredOptions);
  };
  const onSelect = (data: string) => {
    // find bank type
    const selectedBankType =
      allBankData.find((bankData) => bankData.bankList.some((bank) => bank.TenNH === data))?.TenLoaiNganHang || null;

    setSelectedBankType(selectedBankType);
    setSelectedBank(data);
    // setSelectedProvince(null);
    // setSelectedBranch(null);
  };

  const handleBankTypeChange = (value: string) => {
    setSelectedState([true, false, false, false]);
    setSelectedBankType(value);
    // setSelectedBank(null);
    // setSelectedProvince(null);
    // setSelectedBranch(null);
  };

  const handleBankChange = (value: string) => {
    setSelectedState([true, true, false, false]);
    setSelectedBank(value);
    // setSelectedProvince(null);
    // setSelectedBranch(null);
    local.StorageSetSecure("data_bank", { bank: value });
  };

  const handleProvinceChange = (value: string) => {
    setSelectedState([true, true, true, false]);
    setSelectedProvince(value);
    // setSelectedBranch(null);
    local.StorageSetSecure("data_bank", { bank: selectedBank, city: value });
  };

  const handleBranchChange = (value: string) => {
    setSelectedState([true, true, true, true]);
    // setSelectedBranch(value);
    local.StorageSetSecure("data_bank", {
      bank: selectedBank,
      city: selectedProvince,
      bank_branch: value !== null ? value : selectedProvince,
    });
  };

  const selectedBankData = allBankData
    .find((bank) => bank.TenLoaiNganHang === selectedBankType)
    ?.bankList.find((bank) => bank.TenNH === selectedBank);
  const selectedProvinceData = selectedBankData?.province.find(
    (province) => province.TenTinhThanh === selectedProvince,
  );
  const branchList = selectedProvinceData?.branch || [];

  //   const handleConfirm = () => {
  //     navigate(paths.bankInfoPartner, {
  //       state: { bank: selectedBank, city: selectedProvince, bank_branch: selectedBranch },
  //     });
  //   };
  useEffect(() => {
    const selectedBankData = allBankData
      ?.find((bank) => bank.TenLoaiNganHang === selectedBankType)
      ?.bankList.find((bank) => bank.TenNH === selectedBank);
    const selectedProvinceData = selectedBankData?.province.find(
      (province) => province.TenTinhThanh === selectedProvince,
    );
    const branchList = selectedProvinceData?.branch || [];

    if (selectedProvince && branchList.length === 0) {
      setSelectedState([true, true, true, true]);
      local.StorageSetSecure("data_bank", {
        bank: selectedBank,
        city: selectedProvince,
        bank_branch: selectedProvince,
      });
    }
  }, [selectedProvince, selectedBankType, selectedBank, allBankData]);

  return (
    <div className="wrapper select-bank">
      {/* <div className="header">
        <button className="header-back-button" onClick={() => navigate(-1)}>
          <div className="header-back-icon">
            <img src={icon_back} alt="" />
          </div>
          <span className="header-back-text">Quay lại</span>
        </button>
      </div> */}
      <HeaderSevice text={t("chon_ngan_hang")} />
      <div className="select-bank content-container">
        <div className="select-bank-search">
          <AutoComplete
            value={value}
            options={options}
            onSelect={onSelect}
            onChange={onChange}
            className="select-bank-item"
          >
            <Input
              placeholder={t("tim_kiem_ngan_hang")}
              prefix={
                <div className="img-container">
                  <img src={icon_search} alt="" />
                </div>
              }
              className="select-bank-search-input"
            />
          </AutoComplete>
        </div>
        <div className="select-bank-list">
          {loadingBankData && <LoadingParagraph />}
          {allBankData?.length > 0 && (
            <div>
              <Select
                className="select-bank-item"
                size="large"
                placeholder={t("chon_loai_ngan_hang")}
                onChange={handleBankTypeChange}
                value={selectedBankType}
              >
                {allBankData.map((item, index) => (
                  <Select.Option key={index} value={item.TenLoaiNganHang}>
                    {item.TenLoaiNganHang}
                  </Select.Option>
                ))}
              </Select>

              {selectedBankType && (
                <Select
                  className="select-bank-item"
                  size="large"
                  placeholder={t("chon_ngan_hang")}
                  onChange={handleBankChange}
                  value={selectedBank}
                >
                  {allBankData
                    .find((bank) => bank.TenLoaiNganHang === selectedBankType)
                    ?.bankList.map((bank, indexBank) => (
                      <Select.Option key={indexBank} value={bank.TenNH}>
                        {bank.TenNH}
                      </Select.Option>
                    ))}
                </Select>
              )}

              {selectedBank && (
                <Select
                  className="select-bank-item"
                  size="large"
                  placeholder={t("chon_tinh_thanh")}
                  onChange={handleProvinceChange}
                >
                  {allBankData
                    .find((bank) => bank.TenLoaiNganHang === selectedBankType)
                    ?.bankList.find((bank) => bank.TenNH === selectedBank)
                    ?.province.map((province, indexProvince) => (
                      <Select.Option key={indexProvince} value={province.TenTinhThanh}>
                        {province.TenTinhThanh}
                      </Select.Option>
                    ))}
                </Select>
              )}

              {selectedProvince && branchList?.length > 0 ? (
                <Select
                  className="select-bank-item"
                  size="large"
                  placeholder={t("chon_chi_nhanh")}
                  onChange={handleBranchChange}
                >
                  {branchList.map((branch, indexBranch) => (
                    <Select.Option key={indexBranch} value={branch.TenChiNhanh}>
                      {branch.TenChiNhanh}
                    </Select.Option>
                  ))}
                </Select>
              ) : null}
            </div>
          )}
        </div>

        <div className="box-confirm">
          {/* <Button type="primary" block disabled className="confirm-btn" onClick={handleConfirm}>
            Xác nhận
          </Button> */}
          {selectedState[0] && selectedState[1] && selectedState[2] && selectedState[3] ? (
            <Link to="/partner/wallet/bank_info" type="primary" className="confirm-btn primary">
              {t("xac_nhan")}
            </Link>
          ) : (
            <Button className="confirm-btn primary" disabled>
              {t("xac_nhan")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectBank;
