import { Carousel } from "antd";

import image_logo_full from "../../../assets/images/image_logo_full.png";
import HeaderSevice from "../../../components/HeaderService";
import silide1 from "../../../assets/images/about-img/su menh-slide 1 - Cho khach hang.jpg";
import silide2 from "../../../assets/images/about-img/su menh-slide 2 - cho doi tac.jpg";
import silide3 from "../../../assets/images/about-img/su menh-slide 3 - cho nhan vien.jpg";
import silide4 from "../../../assets/images/about-img/su menh-slide 4 - cho co dong.jpg";
import facebook_icon from "../../../assets/icons/help-icon/Facebook.png";
import phone_icon from "../../../assets/icons/help-icon/Phone.png";
import chat_icon from "../../../assets/icons/help-icon/chat.png";
import email_icon from "../../../assets/icons/help-icon/Email.png";
import locate_icon from "../../../assets/icons/help-icon/Locate.png";
import web_icon from "../../../assets/icons/help-icon/Web.png";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import { useEffect } from "react";

const About = () => {
  const imgs = [silide1, silide2, silide3, silide4];
  const { t, i18n } = useTranslation(["home"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);
  return (
    <div className="wrapper terms-of-use">
      <HeaderSevice text={t("ve_bluecare")} />
      <div className="terms-of-use content-container">
        <div className="logo">
          <img src={image_logo_full} alt="" />
        </div>
        <h1 className="title">CÂU CHUYỆN BLUECARE</h1>
        <div className="text">
          <p>
            Bluecare tin rằng mỗi người trong chúng ta đều mong muốn một cuộc sống hạnh phúc hơn. Chúng tôi cảm thấy
            thật tuyệt vời khi có mặt trên cuộc đời này và được giúp đỡ mọi người vơi bớt khổ đau, vượt qua những giai
            đoạn khó khăn nhất của cuộc đời và hướng về 1 cuộc sống an vui, khỏe mạnh.
          </p>
          <p>
            Công việc của chúng tôi là xây dựng một nền tảng di động thông minh, lấy nhân văn là giá trị trung tâm giúp
            mọi người dễ dàng tiếp cận chuỗi dịch vụ chăm sóc sức khỏe toàn diện cho bản thân và gia đình. Khi sức khỏe
            thể chất và sức khỏe tinh thần tốt, cuộc sống của chúng ta trở nên tươi vui, tràn đầy an lạc.
          </p>
          <p>
            Bluecare phấn đấu hết mình nhằm đảm bảo sẽ cung cấp cho bạn những trải nghiệm tuyệt vời về chăm sóc sức khỏe
            toàn diện. “Nhận trách nhiệm – Trao yêu thương” là phương châm Bluecare luôn giữ bên mình, sẵn sàng dấn thân
            hành động ""vì 1 cuộc sống hạnh phúc hơn"" đến cho mọi người.
          </p>
        </div>
        <h1 className="title">TẦM NHÌN</h1>
        <div className="text">
          <p>Trở thành hệ sinh thái chăm sóc y tế và sức khỏe toàn diện tại nhà hàng đầu Việt Nam.</p>
        </div>
        <div className="title">GIÁ TRỊ CỐT LÕI</div>
        <div className="text">
          <ul>
            <li>Nhân văn</li>
            <li>Hợp tác</li>
            <li>Sáng tạo</li>
          </ul>
        </div>
        <h1 className="title">SỨ MỆNH</h1>
        <div className="text">
          <p>
            Bluecare cung cấp một chuỗi các trải nghiệm về chăm sóc y tế chất lượng cao với sự yêu thương chân thành và
            đầy lòng trắc ẩn để đạt đến mục tiêu "Vì một cuộc sống hạnh phúc hơn"
          </p>
          <ul>
            <li>Vì một cuộc sống tốt đẹp hơn cho khách hàng</li>
            <li>Vì một cuộc sống tốt đẹp hơn cho đối tác</li>
            <li>Vì một cuộc sống tốt đẹp hơn cho toàn xã hội</li>
            <li>Vì một cuộc sống hạnh phúc hơn cho nhân viên</li>
            <li>Vì một cuộc sống hạnh phúc hơn cho cổ đông</li>
          </ul>
        </div>
        <Carousel autoplay autoplaySpeed={2000} infinite>
          {imgs?.length > 0 ? (
            imgs.map((image: any, index: number) => (
              <div key={index}>
                <img src={image} alt={`Slide ${index + 1}`} className="slider-image" />
              </div>
            ))
          ) : (
            <></>
          )}
        </Carousel>

        <div className="text">
          <div className="footer-list">
            <div className="footer-item">
              <div className="footer-icon">
                <a href="https://www.facebook.com/bluecarevn" target="_blank" rel="noreferrer">
                  <img src={chat_icon} alt="" />
                </a>
              </div>
            </div>
            <div className="footer-item">
              <div className="footer-icon">
                <a href="https://www.facebook.com/bluecarevn" target="_blank" rel="noreferrer">
                  <img src={web_icon} alt="" />
                </a>
              </div>
            </div>
            <div className="footer-item">
              <div className="footer-icon">
                <a href="mailto:info@bluecare.vn">
                  <img src={email_icon} alt="" />
                </a>
              </div>
            </div>
            <div className="footer-item">
              <div className="footer-icon">
                <a href="tel:0985768181">
                  <img src={phone_icon} alt="" />
                </a>
              </div>
            </div>
            <div className="footer-item">
              {" "}
              <div className="footer-icon">
                <a href="https://www.facebook.com/bluecarevn" target="_blank" rel="noreferrer">
                  <img src={facebook_icon} alt="" />
                </a>
              </div>
            </div>
            <div className="footer-item">
              <div className="footer-icon">
                <a
                  href="https://www.google.com/maps?q=Tầng+9,+Tòa+nhà+Lotus+Building,+Số+2+Duy+Tân,+Cầu+Giấy,+Hà+Nội"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={locate_icon} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <a href="/user/setting/terms_of_use" className="text-bottom">
          Điều khoản sử dụng
        </a>
      </div>
    </div>
  );
};

export default About;
