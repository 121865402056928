import { Navigate, Outlet } from "react-router-dom";
import { getLocalStorage } from "../helper/localStorage";

const ProtectedRoutes = () => {
  const currentUrl = window.location.href;
  const access_token = getLocalStorage("access_token");

  if (currentUrl.includes("user")) {
    return access_token === null ? <Navigate to={"/user/login"} /> : <Outlet />;
  }
  if (currentUrl.includes("partner")) {
    return access_token === null ? <Navigate to={"/partner/login"} /> : <Outlet />;
  }
  return access_token === null ? <Navigate to={"/"} /> : <Outlet />;
};

export default ProtectedRoutes;
