import { useNavigate } from "react-router-dom";
import { Form, Input, notification } from "antd";
import icon_password from "../../../assets/icons/icon_password.png";
import { useAppDispatch } from "../../../redux/hook";
import { changePassUser } from "../../../redux/Slices/userSlice";
import { useEffect, useState } from "react";
import overLoader from "../../../components/overLoader";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import HeaderSevice from "../../../components/HeaderService";

export const ChangePassWordPartner = () => {
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [api, showPopup] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation(["auth", "common"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const onFinish = (value: { oldPassword?: string; newPassword: string }) => {
    setStatusLoader(true);
    const data = {
      current_password: value.oldPassword,
      new_password: value.newPassword,
    };
    try {
      dispatch(changePassUser(data)).then((resultAction: any) => {
        const payload = resultAction.payload as { message: string };
        if (changePassUser.fulfilled.match(resultAction)) {
          alertSuccess(payload.message || "Cập nhật thông tin thành công");
          setStatusLoader(false);
          setTimeout(() => {
            navigate("/partner/home");
          }, 1000);
        } else if (changePassUser.rejected.match(resultAction)) {
          alertFail(payload.message || "Cập nhật thất bại, vui lòng thử lại!");
          setStatusLoader(false);
        }
      });
    } catch (error) {
      console.error(error);
      setStatusLoader(false);
    }
  };

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };

  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  return (
    <div className="wrapper change-password">
      {statusLoader && overLoader()}
      {showPopup}
      <HeaderSevice text={t("doi_mat_khau")} />
      <div className="change-password-form content-container">
        <Form onFinish={onFinish}>
          <div className="form-item">
            <div className="label">
              <div className="icon">
                <img src={icon_password} alt="password" />
              </div>
              <label htmlFor="old-password" className="form-item-label">
                {t("mat_khau_cu")}
              </label>
            </div>
            <Form.Item name="oldPassword" rules={[{ required: true, message: t("yeu_cau_mat_khau") }]}>
              <Input.Password id="old-password" className="form-item-input" placeholder={t("mat_khau_cu")} />
            </Form.Item>
          </div>

          <div className="form-item">
            <div className="label">
              <div className="icon">
                <img src={icon_password} alt="password" />
              </div>
              <label htmlFor="new-password" className="form-item-label">
                {t("mat_khau_moi")}
              </label>
            </div>
            <Form.Item name="newPassword" rules={[{ required: true, message: t("yeu_cau_mat_khau") }]}>
              <Input.Password id="new-password" className="form-item-input" placeholder={t("mat_khau_moi")} />
            </Form.Item>
          </div>

          <div className="form-item">
            <div className="label">
              <div className="icon">
                <img src={icon_password} alt="password" />
              </div>
              <label htmlFor="confirm-password" className="form-item-label">
                {t("xac_nhan_mat_khau")}
              </label>
            </div>

            <Form.Item
              name="confirmPassword"
              dependencies={["newPassword"]}
              hasFeedback
              rules={[
                { required: true, message: t("yeu_cau_mat_khau") },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("mat_khau_khong_khop")));
                  },
                }),
              ]}
            >
              <Input.Password id="confirm-password" className="form-item-input" placeholder={t("xac_nhan_mat_khau")} />
            </Form.Item>
          </div>
          <button className="form-button primary">{t("common:luu")}</button>
        </Form>
      </div>
    </div>
  );
};
