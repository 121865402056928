import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { userApi } from "../../../api/userApi";
import { Input, Rate, notification, Form, Tabs, TabsProps, Modal } from "antd";
import moment from "moment";

import { DataSchedule, RateTasker } from "../../../types/common";
import { COMPANY_PHONE } from "../../../utils/common";
import overLoader from "../../../components/overLoader";

import icon_back from "../../../assets/icons/icon_back.png";
import icon_phone from "../../../assets/icons/icon_phone.png";
import icon_calendar from "../../../assets/icons/icon_calendar.png";
import image_partner_avt from "../../../assets/images/image_logo_horizontal.png";
import { formatPrice } from "../../../utils/func";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";

export default function Calendar() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [rate, setRate] = useState<number>(0);
  //   const [review, setReview] = useState<string>("");
  //   const [calendarSelect, setCalendarSelect] = useState<number>(1);
  //   const [reviewSelect, setReviewSelect] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPartner, setSelectedPartner] = useState<DataSchedule>();
  const [dataCalendar, setDataCalendar] = useState<DataSchedule[]>();
  const [dataOldCalendar, setDataOldCalendar] = useState<DataSchedule[]>();
  const { t, i18n } = useTranslation(["calendar"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const [api, showPopup] = notification.useNotification();
  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const handleGetCalendarList = async () => {
    setStatusLoader(true);
    try {
      const resData: any = await userApi.getCalendar();
      if (resData.status === 1) {
        setStatusLoader(false);
        setDataCalendar(resData.data.schedules);
        setDataOldCalendar(resData.data.old_schedules);
      }
    } catch (err: any) {
      setStatusLoader(false);
    }
  };

  const submitReview = async (review: string, data: RateTasker) => {
    setStatusLoader(true);
    try {
      const resData: any = await userApi.postReview({ ...data, review });
      if (resData.status === 1) {
        setStatusLoader(false);
        alertSuccess(resData.message);
        setTimeout(() => {
          form.resetFields();
          setOpen(false);
          setRate(0);
          handleGetCalendarList();
        }, 2000);
      }
    } catch (err: any) {
      setStatusLoader(false);
      alertFail(err.response.data.message);
    }
  };

  useEffect(() => {
    handleGetCalendarList();
    // eslint-disable-next-line
  }, []);

  //   useEffect(() => {
  //     setRate(0);
  //   }, [reviewSelect]);
  const RatingModal = (open: boolean, onClose: () => void, calendarInfo: DataSchedule) => {
    return (
      <Modal
        open={open}
        centered
        onCancel={onClose}
        title={t("cam_on_ban_da_dat_dich_vu")}
        footer={null}
        className="partner-evaluate"
      >
        <div className="partner-evaluate">
          <div className="partner-evaluate-body">
            {/* <div className="partner-info"> */}
            <div className="partner-avatar">
              <img alt="" src={calendarInfo?.tasker?.avatar ? calendarInfo?.tasker?.avatar : image_partner_avt} />
            </div>
            <span className="partner-name">{calendarInfo?.tasker?.name}</span>
            <span className="partner-speciality">{calendarInfo?.tasker?.job}</span>
            {/* </div> */}
            <span className="partner-evaluate-text">{t("vui_long_danh_gia_dich_vu")}</span>
            <div className="partner-evaluate-star">
              <Rate style={{ fontSize: 25 }} onChange={setRate} value={rate} />
            </div>
            <Form
              form={form}
              onFinish={(e) => {
                submitReview(e.review, {
                  tasker_id: String(calendarInfo?.tasker.id),
                  id: String(calendarInfo?.id),
                  rate: String(rate),
                  review: "",
                });
              }}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item name="review">
                <TextArea
                  rows={5}
                  //   onChange={(e) => setReview(e.target.value)}
                  className="partner-evaluate-comment"
                  placeholder={t("nhan_xet")}
                />
              </Form.Item>
              <button className="partner-evaluate-submit primary">{t("gui_danh_gia")}</button>
            </Form>
          </div>
        </div>
      </Modal>
    );
  };
  const listSchedule: TabsProps["items"] = [
    {
      key: "1",
      label: t("danh_sach_lich_hen"),
      children: (
        <div className="calendar-list">
          {dataCalendar?.map((item, index) => (
            <div key={index} className="calendar-item">
              <div className="calendar-item-info">
                <div className="calendar-icon calendar-schedule">
                  <img alt="" src={icon_calendar} />
                </div>
                <Link to="/user/calendar/detail" state={item} className="calendar-info">
                  <span className="calendar-date">{`${t("ngay")} ${moment(item?.day_working).format(
                    "DD/MM/YYYY",
                  )}`}</span>
                  <table className="calendar-table">
                    <tbody>
                      <tr>
                        <td className="calendar-key">{t("cong_viec")}</td>
                        <td className="calendar-value">{item?.service}</td>
                      </tr>
                      <tr>
                        <td className="calendar-key">{t("gio_bat_dau")}</td>
                        <td className="calendar-value">{item?.time_start}</td>
                      </tr>
                      <tr>
                        <td className="calendar-key">{t("tong_tien")}</td>
                        <td className="calendar-value calendar-price">
                          {/* {`${item?.price} VNĐ`} */}
                          {formatPrice(Number(item?.price))}
                        </td>
                      </tr>
                      <tr>
                        <td className="calendar-key">{t("dia_chi")}</td>
                        <td className="calendar-value">{item?.address}</td>
                      </tr>
                      <tr>
                        <td className="calendar-key">{t("nguoi_thuc_hien")}</td>
                        <td className="calendar-value">{item?.tasker.id !== 0 ? item?.tasker.name : ""}</td>
                      </tr>
                    </tbody>
                  </table>
                </Link>
              </div>
              {item?.tasker?.telephone !== "" && (
                <div className="calendar-item-action">
                  <a href={`tel:${item?.tasker?.telephone}`} className="calendar-contact primary">
                    {t("lien_he")}
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      ),
    },
    {
      key: "2",
      label: t("danh_sach_da_hen"),
      children: (
        <div className="calendar-list">
          {dataOldCalendar?.map((item, index) => (
            <div key={index} className="calendar-item">
              <div className="calendar-item-info">
                <div className="calendar-icon calendar-complete">
                  <img alt="" src={icon_calendar} />
                </div>
                <Link to="/user/calendar/detail" state={item} className="calendar-info">
                  <span className="calendar-date">{`${t("ngay")} ${moment(item?.day_working).format(
                    "DD/MM/YYYY",
                  )}`}</span>
                  <table className="calendar-table">
                    <tbody>
                      <tr>
                        <td className="calendar-key">{t("cong_viec")}</td>
                        <td className="calendar-value">{item?.service}</td>
                      </tr>
                      <tr>
                        <td className="calendar-key">{t("gio_bat_dau")}</td>
                        <td className="calendar-value">{item?.time_start}</td>
                      </tr>
                      <tr>
                        <td className="calendar-key">{t("tong_tien")}</td>
                        <td className="calendar-value calendar-price">
                          {/* {`${item?.price} VNĐ`} */}
                          {formatPrice(Number(item?.price))}
                        </td>
                      </tr>
                      <tr>
                        <td className="calendar-key">{t("dia_chi")}</td>
                        <td className="calendar-value">{item?.address}</td>
                      </tr>
                      <tr>
                        <td className="calendar-key">{t("nguoi_thuc_hien")}</td>
                        <td className="calendar-value">{item?.tasker.id !== 0 ? item?.tasker.name : ""}</td>
                      </tr>
                    </tbody>
                  </table>
                </Link>
              </div>
              {item?.tasker?.id !== 0 && item?.is_rated === 0 ? (
                <div className="calendar-item-action">
                  <button
                    className="calendar-contact primary"
                    onClick={() => {
                      setOpen(true);
                      setSelectedPartner(item);
                    }}
                  >
                    {t("gui_danh_gia")}
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
          {selectedPartner &&
            RatingModal(
              open,
              () => {
                setOpen(false);
                form.resetFields();
              },
              selectedPartner,
            )}
        </div>
      ),
    },
  ];
  return (
    <div className="wrapper my-calendar">
      {statusLoader ? overLoader() : ""}
      {showPopup}
      <div className="header">
        <Link
          to="#"
          onClick={() => {
            navigate(-1);
          }}
        >
          <button className="header-back-button">
            <div className="header-back-icon">
              <img alt="" src={icon_back} />
            </div>
          </button>
        </Link>
        <span className="header-text">{t("lich_cua_toi")}</span>
        <a href={`tel:${COMPANY_PHONE}`} className="header-call no-highlight">
          <img alt="" src={icon_phone} />
        </a>
      </div>
      <div className="calendar">
        {/* <div className="calendar-navbar">
          <div className={`navbar-item ${calendarSelect === 1 ? "active" : ""}`} onClick={() => setCalendarSelect(1)}>
            Danh sách lịch hẹn
          </div>
          <div className={`navbar-item ${calendarSelect === 2 ? "active" : ""}`} onClick={() => setCalendarSelect(2)}>
            Danh sách đã hẹn
          </div>
        </div> */}
        {/* <div className="calendar-list"> */}
        <Tabs
          defaultActiveKey="1"
          items={listSchedule}
          onChange={(key) => {
            Number(key) === 2
              ? document.querySelector(".ant-tabs-ink-bar.ant-tabs-ink-bar-animated")?.classList.add("right")
              : document.querySelector(".ant-tabs-ink-bar.ant-tabs-ink-bar-animated")?.classList.remove("right");
          }}
          className="calendar-tabs"
        />
        {/* </div> */}
      </div>
      {/* <div className={`overlay ${reviewSelect ? "active" : ""}`} onClick={() => setReviewSelect(0)} /> */}
    </div>
  );
}
