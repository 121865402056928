import HeaderSevice from "../../../components/HeaderService";
import facebook_icon from "../../../assets/icons/help-icon/Facebook.png";
import phone_icon from "../../../assets/icons/help-icon/Phone.png";
import chat_icon from "../../../assets/icons/help-icon/chat.png";
import email_icon from "../../../assets/icons/help-icon/Email.png";
import locate_icon from "../../../assets/icons/help-icon/Locate.png";
import web_icon from "../../../assets/icons/help-icon/Web.png";

const Help = () => {
  return (
    <div className="wrapper health-knowledge">
      <HeaderSevice text="Trợ giúp" />
      <div className="health-knowledge content-container">
        <div className="health-knowledge-list">
          <p className="help-item ">Mọi góp ý, yêu cầu hỗ trợ vui lòng liên hệ chúng tôi</p>
        </div>

        <div className="help-list">
          <div className="help-item">
            <div className="help-icon">
              <img src={chat_icon} alt="" />
            </div>
            <span className="help-text">
              <a href="https://www.facebook.com/bluecarevn" target="_blank" rel="noreferrer">
                Chat với chúng tôi
              </a>
            </span>
          </div>
          <div className="help-item">
            {" "}
            <div className="help-icon">
              <img src={web_icon} alt="" />
            </div>
            <span className="help-text">
              <a href="https://bluecare.vn" target="_blank" rel="noreferrer">
                https://bluecare.vn
              </a>
            </span>
          </div>
          <div className="help-item">
            {" "}
            <div className="help-icon">
              <img src={email_icon} alt="" />
            </div>
            <span className="help-text">
              <a href="mailto:info@bluecare.vn">info@bluecare.vn</a>
            </span>
          </div>
          <div className="help-item">
            {" "}
            <div className="help-icon">
              <img src={phone_icon} alt="" />
            </div>
            <span className="help-text">
              <a href="tel:0985768181">098 576 8181</a>
            </span>
          </div>
          <div className="help-item">
            {" "}
            <div className="help-icon">
              <img src={facebook_icon} alt="" />
            </div>
            <span className="help-text">
              <a href="https://www.facebook.com/bluecarevn" target="_blank" rel="noreferrer">
                https://www.facebook.com/bluecarevn
              </a>
            </span>
          </div>
          <div className="help-item">
            {" "}
            <div className="help-icon">
              <img src={locate_icon} alt="" />
            </div>
            <span className="help-text">
              <a
                href="https://www.google.com/maps?q=Tầng+9,+Tòa+nhà+Lotus+Building,+Số+2+Duy+Tân,+Cầu+Giấy,+Hà+Nội"
                target="_blank"
                rel="noreferrer"
              >
                Tầng 9, Tòa nhà Lotus Buiding, Số 2 Duy Tân, Cầu Giấy, Hà Nội
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
