import { useNavigate } from "react-router-dom";

import { Job, TheJobCardProp } from "../../../../types/common";
import icon_calendar from "../../../../assets/icons/icon_calendar.png";
import { formatPrice } from "../../../../utils/func";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../../helper/localStorage";
import { useEffect } from "react";

const TheJobCard: React.FC<TheJobCardProp> = ({ job }) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation(["calendar"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const handleNavigate = (job: Job) => {
    navigate(`/user/job_detail/${job.id}`, { state: { job } });
  };
  return (
    <>
      <div className="task-item">
        <div className="task-icon">
          <img src={icon_calendar} alt="" />
        </div>
        <div className="task-info">
          <h1 className="task-info-name">{job?.service?.category}</h1>
          <table className="task-info-detail">
            <tbody>
              <tr className="task-info-detail-item">
                <td className="task-info-detail-key">{t("ma_dat")}</td>
                <td className="task-info-detail-value">{job.id}</td>
              </tr>
              <tr className="task-info-detail-item">
                <td className="task-info-detail-key">{t("ngay_bat_dau")}</td>
                <td className="task-info-detail-value">{job.detail.day_start}</td>
              </tr>
              <tr className="task-info-detail-item">
                <td className="task-info-detail-key">{t("gio_bat_dau")}</td>
                <td className="task-info-detail-value">{job.detail.time_start}</td>
              </tr>
              {job.detail.num_day ? (
                <tr className="task-info-detail-item">
                  <td className="task-info-detail-key">{t("so_ngay")}</td>
                  <td className="task-info-detail-value">{job.detail.num_day}</td>
                </tr>
              ) : (
                <>
                  <tr className="task-info-detail-item">
                    <td className="task-info-detail-key">{t("ngay_ket_thuc")}</td>
                    <td className="task-info-detail-value">{job.detail.day_end}</td>
                  </tr>
                  <tr className="task-info-detail-item">
                    <td className="task-info-detail-key">{t("gio_ket_thuc")}</td>
                    <td className="task-info-detail-value">{job.detail.time_end}</td>
                  </tr>
                </>
              )}
              <tr className="task-info-detail-item">
                <td className="task-info-detail-key">{t("cong_viec")}</td>
                <td className="task-info-detail-value">{job?.service?.name}</td>
              </tr>
              <tr className="task-info-detail-item">
                <td className="task-info-detail-key">{t("dia_chi")}</td>
                <td className="task-info-detail-value">{job.address}</td>
              </tr>
              <tr className="task-info-detail-item">
                <td className="task-info-detail-key">{t("tong_tien")}</td>
                <td className="task-info-detail-value price">{formatPrice(+job.price)}</td>
              </tr>
            </tbody>
          </table>
          <button onClick={() => handleNavigate(job)} className="task-info-more">
            {t("xem_chi_tiet")}
          </button>
        </div>
      </div>
    </>
  );
};

export default TheJobCard;
