import HeaderSevice from "../../../components/HeaderService";
import icon_chevron_right from "../../../assets/icons/icon_chevron_right.png";

const HealthKnowledge = () => {
  return (
    <div className="wrapper health-knowledge">
      <HeaderSevice text="Kiến thức sức khỏe" />
      <div className="health-knowledge content-container">
        <div className="health-knowledge-list">
          <a href="/#" className="health-knowledge-item">
            <span className="health-knowledge-item-text">Kiến thức mang thai</span>
            <div className="health-knowledge-item-icon">
              <img src={icon_chevron_right} alt="" />
            </div>
          </a>
          <a href="/#" className="health-knowledge-item">
            <span className="health-knowledge-item-text">Thai giáo</span>
            <div className="health-knowledge-item-icon">
              <img src={icon_chevron_right} alt="" />
            </div>
          </a>
          <a href="/#" className="health-knowledge-item">
            <span className="health-knowledge-item-text">Trẻ sơ sinh</span>
            <div className="health-knowledge-item-icon">
              <img src={icon_chevron_right} alt="" />
            </div>
          </a>
          <a href="/#" className="health-knowledge-item">
            <span className="health-knowledge-item-text">Giáo dục sớm</span>
            <div className="health-knowledge-item-icon">
              <img src={icon_chevron_right} alt="" />
            </div>
          </a>
          <a href="/#" className="health-knowledge-item">
            <span className="health-knowledge-item-text">Ăn dặm</span>
            <div className="health-knowledge-item-icon">
              <img src={icon_chevron_right} alt="" />
            </div>
          </a>
          <a href="/#" className="health-knowledge-item">
            <span className="health-knowledge-item-text">Phục hồi chức năng</span>
            <div className="health-knowledge-item-icon">
              <img src={icon_chevron_right} alt="" />
            </div>
          </a>
          <a href="/#" className="health-knowledge-item">
            <span className="health-knowledge-item-text">Châm cứu bấm huyệt</span>
            <div className="health-knowledge-item-icon">
              <img src={icon_chevron_right} alt="" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HealthKnowledge;
