import React from "react";

export const NotifyDetailPartner = () => {
  return (
    <div className="wrapper new-shift-notification">
      <div className="new-shift-notification content-container">
        <div className="list-task">
          <a href="/#" className="task-item">
            <div className="task-item-image">
              <img src="../../assets/images/image_banner_service_info.png" alt="" />
            </div>
            <span className="task-item-name">Chăm sóc bệnh nhân tại nhà</span>
          </a>
          <a href="/#" className="task-item">
            <div className="task-item-image">
              <img src="../../assets/images/image_banner_service_info.png" alt="" />
            </div>
            <span className="task-item-name">Chăm sóc bệnh nhân tại bệnh viện</span>
          </a>
          <a href="/#" className="task-item">
            <div className="task-item-image">
              <img src="../../assets/images/image_banner_service_info.png" alt="" />
            </div>
            <span className="task-item-name">Chăm sóc người già tại nhà</span>
          </a>
          <a href="/#" className="task-item">
            <div className="task-item-image">
              <img src="../../assets/images/image_banner_service_info.png" alt="" />
            </div>
            <span className="task-item-name">Chăm sóc bệnh nhân giai đoạn cuối tại nhà</span>
          </a>
        </div>
      </div>
    </div>
  );
};
