import { Link, useNavigate } from "react-router-dom";

import image_logo_full from "../../../assets/images/image_logo_full.png";
import icon_back from "../../../assets/icons/icon_back.png";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["setting"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  return (
    <div className="wrapper privacy-policy">
      <div className="header">
        <Link to="#" onClick={() => navigate(-1)}>
          <button className="header-back-button">
            <div className="header-back-icon">
              <img src={icon_back} alt="" />
            </div>
          </button>
        </Link>
        <span className="header-text">{t("chinh_sach_bao_mat")}</span>
        <div className="no-action" />
      </div>
      <div className="privacy-policy content-container">
        <div className="logo">
          <img src={image_logo_full} alt="phone" />
        </div>
        <h1 className="title">CHÍNH SÁCH BẢO MẬT THÔNG TIN</h1>
        <div className="text">
          <p>Mục đích và phạm vi thu thập:</p>
          <p>
            - Khi người dùng cài đặt và sử dụng ứng dụng Bluecare trên thiết bị di động, Bluecare sẽ thông báo và thu
            thập những thông tin của người dùng bao gồm: email, điện thoại, tên đăng nhập, mật khẩu đăng nhập, địa chỉ
            của người dùng (thành viên). Đây là các thông tin mà Bluecare cần thành viên cung cấp bắt buộc khi đăng ký
            sử dụng dịch vụ và để Bluecare liên hệ xác nhận khi người dùng đăng ký sử dụng dịch vụ trên Ứng dụng nhằm
            đảm bảo quyền lợi cho cho người dùng.
          </p>
          <p>
            - Các thành viên sẽ tự chịu trách nhiệm về bảo mật và lưu giữ mọi hoạt động sử dụng dịch vụ dưới tên đăng
            ký, mật khẩu và hộp thư điện tử của mình. Ngoài ra, thành viên có trách nhiệm thông báo kịp thời cho Sàn
            giao dịch TMĐT Bluecare về những hành vi sử dụng trái phép, lạm dụng, vi phạm bảo mật, lưu giữ tên đăng ký
            và mật khẩu của bên thứ ba để có biện pháp giải quyết phù hợp.
          </p>
          <p>
            - Bluecare không bắt buộc Người dùng phải sử dụng các dịch vụ đính kèm khi cài đặt và sử dụng ứng dụng
            Bluecare.
          </p>
          <p>Phạm vi sử dụng thông tin:</p>
          <p>Sàn giao dịch TMĐT Bluecare sử dụng thông tin thành viên cung cấp để:</p>
          <p>- Cung cấp các dịch vụ đến Thành viên;</p>
          <p>- Gửi các thông báo về các hoạt động trao đổi thông tin giữa thành viên và Sàn giao dịch TMĐT Bluecare;</p>
          <p>
            - Ngăn ngừa các hoạt động phá hủy tài khoản người dùng của thành viên hoặc các hoạt động giả mạo Thành viên;
          </p>
          <p>- Liên lạc và giải quyết với thành viên trong những trường hợp đặc biệt;</p>
          <p>
            - Trong trường hợp có yêu cầu của pháp luật: Sàn giao dịch TMĐT Bluecare có trách nhiệm hợp tác cung cấp
            thông tin cá nhân thành viên khi có yêu cầu từ cơ quan tư pháp bao gồm: Viện kiểm sát, tòa án, cơ quan công
            an điều tra liên quan đến hành vi vi phạm pháp luật nào đó của người dùng. Ngoài ra, không ai có quyền xâm
            phạm vào thông tin cá nhân của thành viên.
          </p>
          <p>Thời gian lưu trữ thông tin:</p>
          <p>
            Dữ liệu cá nhân của Thành viên sẽ được lưu trữ cho đến khi có yêu cầu hủy bỏ hoặc tự thành viên đăng nhập và
            thực hiện hủy bỏ. Còn lại trong mọi trường hợp thông tin cá nhân thành viên sẽ được bảo mật trên máy chủ của
            Bluecare
          </p>
          <p>Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân:</p>
          <p>- Công ty Cổ phần Bluecare</p>
          <p>
            - Trụ sở chính: số 81, ngách 46, ngõ Linh Quang, đường Tôn Đức Thắng, phường Văn Chương, Quận Đống Đa, Thành
            phố Hà Nội, Việt Nam.
          </p>
          <p>- Email: info@bluecare.vn</p>
          <p>Phương thức và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu cá nhân của mình:</p>
          <p>
            - Thành viên có quyền tự kiểm tra, cập nhật, điều chỉnh hoặc hủy bỏ thông tin cá nhân của mình bằng cách
            đăng nhập vào tài khoản và chỉnh sửa thông tin cá nhân hoặc yêu cầu Bluecare thực hiện việc này.
          </p>
          <p>
            - Cơ chế tiếp nhận và giải quyết khiếu nại của người dùng liên quan đến việc thông tin cá nhân bị sử dụng
            sai mục đích hoặc phạm vi đã thông báo được quy định như sau:
          </p>
          <p>
            + Mọi trường hợp xảy ra tranh chấp khiếu nại sẽ được giải quyết bằng phương pháp thỏa thuận. Trong trường
            hợp thỏa thuận không thành công sẽ nhờ đến sự can thiệp của cơ quan pháp luật xử lý.
          </p>
          <p>
            + Theo đó, người dùng khi có giao dịch mua bán trên ứng dụng BLUECARE yêu cầu phải đăng ký thông tin cá nhân
            đầy đủ và cách thức liên lạc trên ứng dụng BLUECARE. Bên cung ứng dịch vụ phải nêu rõ các thông tin về dịch
            vụ, cách thức sử dụng dịch vụ theo đúng quy định của pháp luật.
          </p>
          <p>+ Các quy trình cụ thể như sau:</p>
          <p>
            Bước 1: Tiếp nhận thông tin về tranh chấp, khiếu nại
            <br />
            Thành viên khiếu nại về tin đăng qua email: info@bluecare.vn hoặc phản ánh trực tiếp qua điện thoại Hotline:
            098 576 8181.
          </p>
          <p>
            Người dùng có thể gửi khiếu nại tại địa chỉ:
            <br />
            Công ty Cổ phần Bluecare
            <br />
            Địa chỉ: Số 81, ngách 46, ngõ Linh Quang, phường Văn Chương, Quận Đống Đa, thành phố Hà Nội
            <br />
            Email: info@bluecare.vn
            <br />
            Hotline: 098 576 8181
          </p>
          <p>
            Bước 2: Xử lý thông tin khiếu nại
            <br />
            Bộ phận Chăm Sóc khách hàng của BLUECARE sẽ tiếp nhận các khiếu nại của thành viên Người dùng/Bên cung ứng
            dịch vụ, tùy theo tính chất và mức độ của khiếu nại thì bên BLUECARE sẽ có những biện pháp cụ thể hỗ trợ
            Người dùng để giải quyết tranh chấp đó.
          </p>
          <p>
            Bước 3: Giải quyết, khắc phục hậu quả sau khi xảy ra tranh chấp, khiếu nại nếu như thiệt hại xảy ra có liên
            quan đến ứng dụng.
          </p>
          <p>
            + Tùy theo mức độ vi phạm của Bên cung ứng dịch vụ, Ban quản lý sẽ quyết định gỡ bỏ toàn bộ tin đăng của Bên
            cung ứng dịch vụ đó, đồng thời sẽ chấm dứt quyền thành viên.
          </p>
          <p>
            + BLUECARE tôn trọng và nghiêm túc thực hiện các quy định của pháp luật về bảo vệ quyền lợi của Người dùng
            (người dùng). Vì vậy, đề nghị các thành viên đăng tin đăng vặt dịch vụ, dịch vụ trên sàn cung cấp đầy đủ,
            chính xác, trung thực và chi tiết các thông tin liên quan đến dịch vụ, dịch vụ. Mọi hành vi lừa đảo, gian
            lận trong kinh doanh đều bị lên án và phải chịu hoàn toàn trách nhiệm trước pháp luật.
          </p>
          <p>
            + Các bên bao gồm Bên cung ứng dịch vụ, Người dùng sẽ phải có vai trò trách nhiệm trong việc tích cực giải
            quyết vấn đề. Đối với Bên cung ứng dịch vụ hàng cần có trách nhiệm cung cấp văn bản giấy tờ chứng thực thông
            tin liên quan đến sự việc đang gây mâu thuẫn cho người dùng. Đối với BLUECARE sẽ có trách cung cấp những
            thông tin liên quan đến Người dùng và Bên cung ứng dịch vụ nếu được Người dùng hoặc Bên cung ứng dịch vụ
            (liên quan đến tranh chấp đó) yêu cầu.
          </p>
          <p>
            + Tùy theo mức độ vi phạm của Bên cung ứng dịch vụ, Ban quản lý sẽ quyết định gỡ bỏ toàn bộ tin đăng của Bên
            cung ứng dịch vụ đó, đồng thời sẽ chấm dứt quyền thành viên.
          </p>
          <p>
            + Nếu thông qua hình thức thỏa thuận mà vẫn không thể giải quyết được mâu thuẫn phát sinh từ giao dịch giữa
            các bên thì một trong 2 bên người dùng và Bên cung ứng dịch vụ sẽ có quyền nhờ đến cơ quan pháp luật có thẩm
            quyền can thiệp nhằm đảm bảo lợi ích hợp pháp của các bên nhất là cho người dùng.
          </p>
          <p>Cam kết bảo mật thông tin cá nhân người dùng:</p>
          <p>
            - Thông tin cá nhân của thành viên trên Bluecare được Bluecare cam kết bảo mật tuyệt đối theo chính sách bảo
            vệ thông tin cá nhân của Bluecare. Việc thu thập và sử dụng thông tin của mỗi thành viên chỉ được thực hiện
            khi có sự đồng ý của người dùng đó trừ những trường hợp pháp luật có quy định khác.
          </p>
          <p>
            - Không sử dụng, không chuyển giao, cung cấp hay tiết lộ cho bên thứ 3 nào về thông tin cá nhân của thành
            viên khi không có sự cho phép đồng ý từ thành viên.
          </p>
          <p>
            - Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát dữ liệu cá nhân thành viên,
            Bluecare sẽ có trách nhiệm thông báo vụ việc cho cơ quan chức năng điều tra xử lý kịp thời và thông báo cho
            thành viên được biết.
          </p>
          <p>
            Ban quản lý Bluecare không chịu trách nhiệm cũng như không giải quyết mọi khiếu nại có liên quan đến quyền
            lợi của thành viên đó nếu xét thấy tất cả thông tin cá nhân của thành viên đó cung cấp khi đăng ký ban đầu
            là không chính xác.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
