import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import HeaderSevice from "../../../components/HeaderService";
import overLoader from "../../../components/overLoader";

import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getServices } from "../../../redux/Slices/serviceSlice";
import { ServiceData } from "../../../types/service";
import { getPathByIndex } from "../../../utils/func";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

export const ListServceByCategoryId = (categoryId: string) => {
  const dispatch = useAppDispatch();
  const serviceList: ServiceData[] = useAppSelector((state) => state.service.serviceList);
  const loadingService = useAppSelector((state) => state.service.loadingService);
  const categoryName = useAppSelector((state) => state.service.categoryName.category_name);
  const id = getPathByIndex(3);
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    if (id) {
      dispatch(getServices(id));
    }
  }, [categoryId, dispatch, id]);

  return (
    <div className="wrapper service">
      {loadingService && overLoader()}
      <HeaderSevice text={categoryName} />
      <div className="service-detail content-container">
        <div className="service-detail-list">
          {serviceList?.length > 0
            ? !loadingService &&
              serviceList.map((item, index) => (
                <Link key={index} to={`/user/service_detail/${item.id}`} className="service-detail-item">
                  <div className="service-detail-item-icon">
                    <img src={item.image} alt="" />
                  </div>
                  <span className="service-detail-item-text">{item.name}</span>
                </Link>
              ))
            : !loadingService && (
                <Modal
                  title={t("thong_bao")}
                  centered
                  open={true}
                  onCancel={() => navigate(-1)}
                  footer={() => (
                    <Button type="primary" onClick={() => navigate(-1)}>
                      {t("thoat")}
                    </Button>
                  )}
                >
                  <p>{t("khong_co_du_lieu")}</p>
                </Modal>
              )}
        </div>
      </div>
    </div>
  );
};
