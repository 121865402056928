import { Button, DatePicker, Form, InputNumber, notification, TimePicker } from "antd";
import type { DatePickerProps } from "antd";

import HeaderSevice from "../../../components/HeaderService";

import icon_add from "../../../assets/icons/icon_add.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import dayjs from "dayjs";
import { calculatePrice } from "../../../redux/Slices/serviceSlice";
import TextArea from "antd/es/input/TextArea";
import local, { getLocalStorage } from "../../../helper/localStorage";
import { formatPrice, removeAccents } from "../../../utils/func";
import { useTranslation } from "react-i18next";

export const Booking = () => {
  const [startDate, setStartDate] = useState<string>("");
  const [startTime, setStartTime] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [numDay, setNumDay] = useState<number>(0);
  const [stateCoupon, setStateCoupon] = useState<boolean>(false);
  //   const [numDay, setNumDay] = useState<number>(0);
  const [stateCalc, setStateCalc] = useState<boolean>(false);
  const paramType = useAppSelector((state) => state.service.paramsType);
  const templateType = useAppSelector((state) => state.service.templateType);
  const userFamily = useAppSelector((state) => state.user.familyUser);
  const service = useAppSelector((state) => state.service);
  const [api, showPopup] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const data_booking = local.StorageGetSecure("data_booking");
  const fm_info = userFamily?.datas?.find((item: any) => item.fm_id === data_booking?.fm_id);
  const { t, i18n } = useTranslation(["booking"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };

  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const onChangeStartDate: DatePickerProps["onChange"] = (date, dateString) => {
    setStartDate(date?.format("YYYY-MM-DD").toString());
    setStateCalc(false);
    local.StorageSetSecure("data_booking", { ...data_booking, day_start: date?.format("YYYY-MM-DD").toString() });
  };

  const onChangeEndDate: DatePickerProps["onChange"] = (date, dateString) => {
    setEndDate(date?.format("YYYY-MM-DD").toString());
    setStateCalc(false);
    local.StorageSetSecure("data_booking", { ...data_booking, day_end: date?.format("YYYY-MM-DD").toString() });
  };

  const onChangeStartTime: DatePickerProps["onChange"] = (date, dateString) => {
    setStartTime(dateString.toString());
    setStateCalc(false);
    local.StorageSetSecure("data_booking", {
      ...data_booking,
      time_start: dateString,
      time_end: dayjs(dateString.toString(), "HH:mm").add(3, "hour")?.format("HH:mm"),
    });
    form.setFieldsValue({ time_end: dayjs(dateString.toString(), "HH:mm").add(3, "hour") });
  };

  const onChangeEndTime: DatePickerProps["onChange"] = (date, dateString) => {
    setEndTime(dateString.toString());
    setStateCalc(false);
    local.StorageSetSecure("data_booking", { ...data_booking, time_end: dateString });
  };

  const onChangeNumDay = (e: any) => {
    setNumDay(e);
    // console.log(typeof e);
    setStateCalc(false);
    local.StorageSetSecure("data_booking", { ...data_booking, num_day: Number(e) });
  };
  //   console.log(data_booking);
  useEffect(() => {
    templateType === 0 && navigate("/user/home");
  }, [navigate, templateType]);
  const handleBooking = async (data: any) => {
    // console.log({
    //   ...data,
    //   id: service.id,
    //   "start-date": startDate,
    //   "start-time": startTime,
    //   "end-date": endDate,
    //   "end-time": endTime,
    // });
    const province = removeAccents(
      data_booking.city.replace("Thành phố", "").replace("Tỉnh", "").replaceAll(" ", "").trim().toLowerCase(),
    );
    const dataSend = {
      ...data,
      id: service.serviceDetail.id,
      province: province === "hanoi" || province === "hochiminh" ? province : "",
      day_start: data_booking?.day_start ? data_booking?.day_start : startDate,
      time_start: data_booking?.time_start ? data_booking?.time_start : startTime,
      day_end: data_booking?.day_end ? data_booking?.day_end : endDate,
      time_end: data_booking?.time_end ? data_booking?.time_end : endTime,
      //   num_day: data_booking?.num_day ? data_booking?.num_day : numDay,
      //   templateType === 1 || (templateType === 3 && paramType === 2) && num_day: data_booking?.num_day ? data_booking?.num_day : numDay,
      num_day:
        templateType === 1 || (templateType === 3 && paramType === 2)
          ? data_booking?.num_day
            ? data_booking?.num_day
            : numDay
          : "",
      params_type: paramType !== 0 ? paramType : "",
      note: data_booking?.note ? data_booking?.note : "",
    };

    // console.log(dataSend);
    try {
      const resData: any = await dispatch(calculatePrice(dataSend));
      if (resData.payload.status === 1) {
        alertSuccess(resData.payload.message);
        setStateCalc(true);
      } else if (resData.payload.status === 0) {
        alertFail(resData.payload.message);
        // setStateCalc(false);
        if (resData.payload.data) {
          setStateCalc(true);
        } else {
          setStateCalc(false);
        }
      } else {
        alertFail(resData.message);
        // setStateCalc(true);
      }
    } catch (error: any) {
      alertFail(error.message);
      //   setStateCalc(true);
    }
  };
  const disabledHourStart = () => {
    let result: number[] = [];
    const dayStart = dayjs(data_booking?.day_start).format("DD-MM-YYYY");
    const timeCurrentAddHour = dayjs().add(1.5, "hour");
    if (templateType !== 3) {
      if (dayStart === dayjs().format("DD-MM-YYYY")) {
        result = Array.from({ length: 24 }, (_, i) => i).filter((i) => i < 6 || i < timeCurrentAddHour.hour());
      } else {
        result = Array.from({ length: 24 }, (_, i) => i).filter((i) => i < 6);
      }
    } else if (templateType === 3) {
      if (paramType === 1) {
        if (dayStart === dayjs().format("DD-MM-YYYY")) {
          result = Array.from({ length: 24 }, (_, i) => i).filter(
            (i) => i < 7 || i < timeCurrentAddHour.hour() || i > 20,
          );
        } else {
          result = Array.from({ length: 24 }, (_, i) => i).filter((i) => i < 7 || i > 20);
        }
      } else if (paramType === 2) {
        if (dayStart === dayjs().format("DD-MM-YYYY")) {
          result = Array.from({ length: 24 }, (_, i) => i).filter(
            (i) => i < 7 || i < timeCurrentAddHour.hour() || i > 22,
          );
        } else {
          result = Array.from({ length: 24 }, (_, i) => i).filter((i) => i < 7 || i > 22);
        }
      }
    }
    return result;
  };
  return (
    <div className="wrapper booking">
      {showPopup}
      <HeaderSevice text={t("thong_tin_dat_lich")} />
      <Form className="booking-form content-container has-bottom-button" form={form} onFinish={handleBooking}>
        <div className={`booking-form-wrapper${templateType !== 3 ? " only-num-day" : ""}`}>
          <Form.Item
            name="day_start"
            className="booking-form-item has-icon"
            rules={[{ required: true, message: t("vui_long_chon_ngay_bat_dau") }]}
            initialValue={data_booking?.day_start ? dayjs(data_booking?.day_start) : null}
          >
            <DatePicker
              format="DD-MM-YYYY"
              minDate={dayjs().hour() >= 20 ? dayjs().add(1, "day") : dayjs()}
              onChange={onChangeStartDate}
              className="booking-form-input"
              placeholder={t("ngay_bat_dau")}
            />
          </Form.Item>
          <Form.Item
            name="time_start"
            className="booking-form-item has-icon"
            rules={[{ required: true, message: t("vui_long_chon_gio_bat_dau") }]}
            initialValue={data_booking?.time_start ? dayjs(data_booking?.time_start, "HH:mm") : null}
          >
            {/* <Tooltip title="Chỉ được chọn giờ bắt đầu sau 1.5 giờ so với giờ hiện tại"> */}
            <TimePicker
              format="HH:mm"
              needConfirm={false}
              onChange={onChangeStartTime}
              // onCalendarChange={(date, dateString) => setStartHour(Number(dateString.slice(0, 2)))}
              className="booking-form-input"
              placeholder={t("gio_bat_dau")}
              disabled={data_booking?.day_start ? !data_booking?.day_start : !startDate}
              disabledTime={() => ({
                // disabled hour from 0 to 6 and before current hour and 1.5 hour after current hour
                disabledHours: () => disabledHourStart(),
                disabledMinutes: (hour: number) => {
                  const dayStart = dayjs(data_booking?.day_start).format("DD-MM-YYYY");
                  const timeCurrentAddHour = dayjs().add(1.5, "hour");
                  if (hour === timeCurrentAddHour.hour() && dayStart === dayjs().format("DD-MM-YYYY")) {
                    return Array.from({ length: 60 }, (_, i) => i).filter((i) => i <= timeCurrentAddHour.minute());
                  }
                  if (templateType === 3 && paramType === 2) {
                    if (hour === 22) {
                      return Array.from({ length: 60 }, (_, i) => i).filter((i) => i >= 30);
                    }
                  }
                  return Array.from({ length: 60 }, (_, i) => i).filter((i) => i % 30 !== 0);
                },
              })}
              minuteStep={30}
            />
            {/* </Tooltip> */}
          </Form.Item>
          {templateType === 1 ? (
            <Form.Item
              name="num_day"
              className="booking-form-item has-icon"
              rules={[
                { required: true, message: t("vui_long_nhap_so_ngay") },
                { type: "number", min: 1, max: 30, message: t("so_ngay_tu_1_den_30") },
              ]}
              initialValue={data_booking?.num_day ? Number(data_booking?.num_day) : null}
            >
              <InputNumber
                // type="number"
                // inputMode="numeric"
                onChange={onChangeNumDay}
                placeholder={t("so_ngay")}
                className="booking-form-input num-day"
              />
            </Form.Item>
          ) : templateType === 3 && paramType === 1 ? (
            <>
              <Form.Item
                name="day_end"
                className="booking-form-item has-icon"
                rules={[{ required: true, message: t("vui_long_chon_ngay_ket_thuc") }]}
                initialValue={data_booking?.day_end ? dayjs(data_booking?.day_end) : null}
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  onChange={onChangeEndDate}
                  minDate={dayjs(data_booking?.day_start)}
                  disabled={data_booking?.day_start ? !data_booking?.day_start : !startDate}
                  className="booking-form-input"
                  placeholder={t("ngay_ket_thuc")}
                />
              </Form.Item>
              <Form.Item
                name="time_end"
                className="booking-form-item has-icon"
                rules={[{ required: true, message: t("vui_long_chon_gio_ket_thuc") }]}
                initialValue={data_booking?.time_end ? dayjs(data_booking?.time_end, "HH:mm") : null}
              >
                <TimePicker
                  format="HH:mm"
                  needConfirm={false}
                  onChange={onChangeEndTime}
                  //   value={dayjs(data_booking?.time_end, "HH:mm")}
                  disabled={data_booking?.day_end ? !data_booking?.day_end : !endDate}
                  // disabled
                  className="booking-form-input"
                  placeholder={t("gio_ket_thuc")}
                  disabledTime={() => ({
                    // only enable hour 3 hours after start time
                    disabledHours: () => {
                      if (
                        dayjs(data_booking?.day_start)?.format("DD-MM-YYYY") ===
                        dayjs(data_booking?.day_end)?.format("DD-MM-YYYY")
                      ) {
                        return Array.from({ length: 24 }, (_, i) => i).filter(
                          (i) =>
                            // i < dayjs(data_booking?.time_start, "HH:mm").hour() ||
                            // i < dayjs().hour() ||
                            i < dayjs(data_booking?.time_start, "HH:mm").add(3, "hour").hour(),
                        );
                      }
                      return Array.from({ length: 24 }, (_, i) => i).filter((i) => i < 7);
                    },
                    disabledMinutes: (hour: number) => {
                      if (
                        dayjs(data_booking?.day_start)?.format("DD-MM-YYYY") ===
                          dayjs(data_booking?.day_end)?.format("DD-MM-YYYY") &&
                        hour === dayjs(data_booking?.time_start, "HH:mm").add(3, "hour").hour()
                      ) {
                        return Array.from({ length: 60 }, (_, i) => i).filter(
                          (i) =>
                            // i < dayjs(data_booking?.time_start, "HH:mm").minute() ||
                            // i < dayjs().minute() ||
                            i < dayjs(data_booking?.time_start, "HH:mm").add(3, "hour").minute(),
                        );
                      }
                      return Array.from({ length: 60 }, (_, i) => i).filter((i) => i % 30 !== 0);
                    },
                  })}
                  minuteStep={30}
                />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                name="num_day"
                className="booking-form-item has-icon"
                rules={[
                  { required: true, message: t("vui_long_nhap_so_ngay") },
                  {
                    type: "number",
                    min: 1,
                    max: 30,
                    message: t("so_ngay_tu_1_den_30"),
                  },
                ]}
                initialValue={data_booking?.num_day ? data_booking?.num_day : null}
              >
                <InputNumber
                  // type="number"
                  // inputMode="numeric"
                  onChange={onChangeNumDay}
                  placeholder={t("so_ngay")}
                  className="booking-form-input num-day"
                />
              </Form.Item>
              <Form.Item
                name="time_end"
                className="booking-form-item has-icon"
                rules={[{ required: true, message: t("vui_long_chon_gio_ket_thuc") }]}
                initialValue={data_booking?.time_end ? dayjs(data_booking?.time_end, "HH:mm") : null}
              >
                <TimePicker
                  format="HH:mm"
                  needConfirm={false}
                  //   disabled
                  onChange={onChangeEndTime}
                  className="booking-form-input"
                  placeholder={t("gio_ket_thuc")}
                  disabledTime={() => ({
                    disabledHours: () => {
                      const dateStart = dayjs(
                        `${data_booking?.day_start} ${data_booking?.time_start}`,
                        "YYYY-MM-DD HH:mm",
                      );
                      const timeStartAdd3Hour = dayjs(data_booking?.time_start, "HH:mm").add(3, "hour");
                      const dayStartAdd1Day = dayjs(data_booking?.day_start, "YYYY-MM-DD").add(1, "day").startOf("day");
                      if (
                        dateStart.add(3, "hour").isAfter(dayStartAdd1Day) ||
                        dateStart.add(3, "hour").isSame(dayStartAdd1Day)
                      ) {
                        return Array.from({ length: 24 }, (_, i) => i).filter(
                          (i) => i > 7 || i < timeStartAdd3Hour.hour(),
                        );
                      }
                      return Array.from({ length: 24 }, (_, i) => i).filter(
                        (i) => i < timeStartAdd3Hour.hour() && i > 7,
                      );
                    },
                    disabledMinutes: (hour: number) => {
                      const dateStart = dayjs(
                        `${data_booking?.day_start} ${data_booking?.time_start}`,
                        "YYYY-MM-DD HH:mm",
                      );
                      const timeStart = dayjs(data_booking?.time_start, "HH:mm");
                      const dayStartAdd1Day = dayjs(data_booking?.day_start, "YYYY-MM-DD").add(1, "day").startOf("day");
                      if (
                        (dateStart.add(3, "hour").isAfter(dayStartAdd1Day) ||
                          dateStart.add(3, "hour").isSame(dayStartAdd1Day)) &&
                        hour === timeStart.add(3, "hour").hour()
                      ) {
                        // console.log("aaa");
                        return Array.from({ length: 60 }, (_, i) => i).filter((i) => i < timeStart.minute());
                      } else if (
                        // (dateStart.add(3, "hour").isAfter(dayStartAdd1Day) ||
                        //   dateStart.add(3, "hour").isSame(dayStartAdd1Day)) &&
                        hour === 7
                      ) {
                        return Array.from({ length: 60 }, (_, i) => i).filter((i) => i >= 30);
                      }
                      //   if (hour === 7) {
                      //     return Array.from({ length: 60 }, (_, i) => i).filter((i) => i >= 30);
                      //   }
                      return Array.from({ length: 60 }, (_, i) => i).filter((i) => i % 30 !== 0);
                    },
                  })}
                  minuteStep={30}
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            name="booking-note"
            className="booking-form-item"
            initialValue={data_booking?.note ? data_booking?.note : ""}
          >
            <TextArea
              rows={4}
              name="booking-note"
              id="booking-note"
              className="booking-form-input textarea"
              placeholder={t("ghi_chu")}
              onChange={(e) => {
                setStateCalc(false);
                local.StorageSetSecure("data_booking", { ...data_booking, note: e.target.value });
              }}
            />
          </Form.Item>
          <Form.Item name="voucher_code" className="booking-form-item">
            <input
              type="text"
              id="booking-coupon"
              className="booking-form-input"
              placeholder={t("ma_giam_gia")}
              //   value=""
              onChange={(e) => {
                setStateCalc(false);
                e.target.value !== "" ? setStateCoupon(true) : setStateCoupon(false);
              }}
            />
          </Form.Item>
          <div className="booking-form-item">
            <button className="booking-coupon-button primary" disabled={!stateCoupon}>
              {t("ap_dung")}
            </button>
          </div>
        </div>
        {fm_info ? (
          <Link to="/user/booking/user" className="select-user">
            <div className="select-user-icon">
              <img src={icon_add} alt="" />
            </div>
            <span className="select-user-text">{fm_info?.name}</span>
          </Link>
        ) : (
          <Link to="/user/booking/user" className="select-user">
            <div className="select-user-icon">
              <img src={icon_add} alt="" />
            </div>
            <span className="select-user-text">{t("chon_nguoi_su_dung_dich_vu")}</span>
          </Link>
        )}
        {stateCalc && (
          <div className="amount">
            <div className="amount-item">
              <span className="amount-text">{t("gia_tien")}</span>
              <span className="amount-value">
                {/* {service?.servicePrice?.data?.before_sale}đ */}
                {formatPrice(service?.servicePrice?.data?.before_sale.toString().replaceAll(",", ""))}
              </span>
            </div>
            <div className="amount-item">
              <span className="amount-text">{t("giam_gia")}</span>
              <span className="amount-value">
                {/* -{service?.servicePrice?.data?.sale_off}đ */}-
                {formatPrice(service?.servicePrice?.data?.sale_off.toString().replaceAll(",", ""))}
              </span>
            </div>
            <div className="amount-item">
              <span className="amount-text">{t("tong_tien")}</span>
              <span className="amount-value">
                {/* {service?.servicePrice?.data?.money}đ */}
                {formatPrice(service?.servicePrice?.data?.money.toString().replaceAll(",", ""))}
              </span>
            </div>
          </div>
        )}
        <div className="booking-button-wrapper">
          {/* <Link
            to="/user/booking/payment"
            state={{
              address: `${state.addres}, ${state.selectedWard}, ${state.selectedDistrict}, ${state.selectedCity}`,
              startDate,
              startTime,
              numDay,
            }}
            className="booking-button primary"
          >
            Tiếp theo
          </Link> */}
          {/* {service.servicePrice !== "" ? (
            <Link to="/user/booking/payment" className="booking-button primary">
              Tiếp theo
            </Link>
          ) : templateType !== 3 && startDate !== "" && startTime !== "" && numDay !== 0 ? (
            <Button
              type="primary"
              htmlType="submit"
              loading={service.loadingCaclPrice}
              onClick={() => {
                handleBooking({
                  num_day: numDay.toString(),
                  voucher_code: "",
                });
              }}
              className="booking-button primary"
            >
              Tiếp theo
            </Button>
          ) : templateType === 3 &&
            paramType === 1 &&
            startDate !== "" &&
            startTime !== "" &&
            endDate !== "" &&
            endTime !== "" ? (
            <Button
              type="primary"
              htmlType="submit"
              loading={service.loadingCaclPrice}
              className="booking-button primary"
            >
              Tiếp theo
            </Button>
          ) : templateType === 3 &&
            paramType === 2 &&
            startDate !== "" &&
            startTime !== "" &&
            numDay !== 0 &&
            endTime !== "" ? (
            <Button
              type="primary"
              htmlType="submit"
              loading={service.loadingCaclPrice}
              className="booking-button primary"
            >
              Tiếp theo
            </Button>
          ) : (
            <Button type="primary" htmlType="button" className="booking-button primary">
              Tiếp theo
            </Button>
          )} */}
          {!stateCalc ? (
            <Button
              type="primary"
              htmlType="submit"
              loading={service.loadingCaclPrice}
              className="booking-button primary"
            >
              {t("tiep_theo")}
            </Button>
          ) : (
            <Link to="/user/booking/payment" className="booking-button primary">
              {t("tiep_theo")}
            </Link>
          )}
        </div>
      </Form>
    </div>
  );
};
