import { useEffect } from "react";
import HeaderSevice from "../../../components/HeaderService";
import { useNavigate } from "react-router-dom";
import { Form, Radio } from "antd";
import local, { getLocalStorage } from "../../../helper/localStorage";
import { useTranslation } from "react-i18next";

export const Selectpayment = () => {
  const [form] = Form.useForm();
  //   const [selectState, setSelectState] = useState<boolean>(false);
  const navigate = useNavigate();
  const data_booking = local.StorageGetSecure("data_booking");
  //   const userId = useLocation().state.user_id;
  //   const [selectedUserId, setSelectedUserId] = useState<string>("");
  //   const userID = useAppSelector((state) => state.user.userInfor?.id);
  const { t, i18n } = useTranslation(["booking"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  //   const handleSelectUser = (item: any) => {
  //     // setSelectState(true);
  //     local.StorageSetSecure("data_booking", { ...data_booking, payment_method: item.target.value });
  //   };

  const handleSubmit = (data: any) => {
    local.StorageSetSecure("data_booking", { ...data_booking, payment_method: data.payment_method });
    navigate("/user/booking/confirm");
  };

  return (
    <div className="wrapper select-payment">
      <HeaderSevice text={t("chon_phuong_thuc_thanh_toan")} />
      <div className="select-payment content-container">
        <Form className="select-payment-method" onFinish={handleSubmit} form={form}>
          <Form.Item
            name="payment_method"
            className="select-payment-method-item"
            rules={[{ required: true, message: t("vui_long_chon_phuong_thuc_thanh_toan") }]}
            initialValue={data_booking?.payment_method ? data_booking?.payment_method : 0}
          >
            {/* <label htmlFor="payment-method-cash" className="payment-method-label" onClick={() => handleSelectUser(1)}>
              <input
                type="radio"
                name="payment-method"
                id="payment-method-cash"
                className="payment-method-checkbox"
                required
                checked
              />
              <span className="payment-method-text">Thanh toán bằng tiền mặt</span>
            </label> */}
            <Radio.Group className="payment-method-group">
              <Radio value={0} className="payment-method-checkbox">
                {t("tien_mat")}
              </Radio>
              <Radio value={1} disabled className="payment-method-checkbox">
                {t("vi")}
              </Radio>
              <Radio value={2} className="payment-method-checkbox">
                {t("chuyen_khoan")}
              </Radio>
            </Radio.Group>
          </Form.Item>
          {/* <div className="select-payment-method-item">
            <label htmlFor="payment-method-wallet" className="payment-method-label" onClick={() => handleSelectUser(0)}>
              <input
                type="radio"
                name="payment-method"
                id="payment-method-wallet"
                className="payment-method-checkbox"
                required
              />
              <span className="payment-method-text">Thanh toán bằng ví</span>
            </label>
          </div> */}
          <div className="booking-button-wrapper">
            <button className="booking-button primary">{t("tiep_theo")}</button>
            {/* {!selectState ? (
              <button className="booking-button primary">{t("tiep_theo")}</button>
            ) : (
            <Link
              to="/user/booking/confirm"
              className="booking-button primary"
              //   onClick={() => navigate("/user/booking/confirm", { state: { user_id: userID, payment: selectedUserId } })}
            >
              {t("tiep_theo")}
            </Link>
            )} */}
          </div>
        </Form>
      </div>
    </div>
  );
};
