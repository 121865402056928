import HeaderSevice from "../../../components/HeaderService";
import icon_chevron_right from "../../../assets/icons/icon_chevron_right.png";

const FreeCourse = () => {
  return (
    <div className="wrapper free-course">
      <HeaderSevice text="Khóa học miễn phí" />
      <div className="free-course content-container">
        <div className="free-course-list">
          <a href="/#" className="free-course-item">
            <span className="free-course-item-text">Thai giáo</span>
            <div className="free-course-item-icon">
              <img src={icon_chevron_right} alt="" />
            </div>
          </a>
          <a href="/#" className="free-course-item">
            <span className="free-course-item-text">Kiến thức thai giáo</span>
            <div className="free-course-item-icon">
              <img src={icon_chevron_right} alt="" />
            </div>
          </a>
          <a href="/#" className="free-course-item">
            <span className="free-course-item-text">Nhạc Thai giáo</span>
            <div className="free-course-item-icon">
              <img src={icon_chevron_right} alt="" />
            </div>
          </a>
          <a href="/#" className="free-course-item">
            <span className="free-course-item-text">Giáo dục sớm</span>
            <div className="free-course-item-icon">
              <img src={icon_chevron_right} alt="" />
            </div>
          </a>
          <a href="/#" className="free-course-item">
            <span className="free-course-item-text">Chuyện nuôi con</span>
            <div className="free-course-item-icon">
              <img src={icon_chevron_right} alt="" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FreeCourse;
