import { useEffect } from "react";
import { Link } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getAllVoucherUser } from "../../../redux/Slices/userSlice";
import HeaderSevice from "../../../components/HeaderService";
import overLoader from "../../../components/overLoader";
import { Voucher } from "../../../types/common";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";

const Promotion = () => {
  const dispatch = useAppDispatch();
  const voucherData = useAppSelector((state) => state.user.voucher);
  const { t, i18n } = useTranslation(["home"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    dispatch(getAllVoucherUser());
  }, [dispatch]);

  const renderVourcherItem = () => {
    return voucherData?.datas?.length > 0
      ? voucherData?.datas.map((item: Voucher, index: number) => (
          <Link to={`/user/promotion_detail/${item.id}`} key={index} className="promotion-item">
            <div className="promotion-item-icon">
              <img alt="img_promotion" src={item.thumbnail} />
            </div>
            <div className="promotion-item-content">
              <h1 className="promotion-item-title">{item.title}</h1>
              <p className="promotion-item-description">{item?.desc}</p>
            </div>
          </Link>
        ))
      : overLoader();
  };

  return (
    <div className="wrapper promotion">
      <HeaderSevice text={t("khuyen_mai")} />
      <div className="promotion-list content-container">{renderVourcherItem()}</div>
    </div>
  );
};

export default Promotion;
