const paths = {
  // PARTNER PATH
  homePartner: "/partner/home",
  settingPartner: "/partner/setting",
  changePasswordPartner: "/partner/setting/change_password",
  changeLanguagePartner: "/partner/setting/change_language",
  myAccountPartner: "/partner/setting/my_account",
  termsPartner: "/partner/setting/terms_of_use",
  privacyPolicyPartner: "/partner/setting/privacy_policy",
  //---banner partner
  calendarPartner: "/partner/calendar",
  calendarPartnerDetail: "/partner/calendar/detail",
  reportPartner: "/partner/report",
  walletPartner: "/partner/wallet",
  bankInfoPartner: "/partner/wallet/bank_info",
  ruleWalletPartner: "/partner/wallet/rule_get_money",
  selectBank: "/partner/wallet/select_bank",
  taskPartner: "/partner/task",
  taskDetailPartner: "/partner/task/detail",
  statisticPartner: "/partner/statistic",
  registerServicePartner: "/partner/register_service",
  registerServiceDetailPartner: "/partner/register_sevice/detail",
  registerSubServiceDetailPartner: "/partner/register_sevice/detail/sub_service",
  notifyPartner: "/partner/notify",
  notifyDetailPartner: "/partner/notify/detail",
  aboutPartner: "/partner/about",
  helpPartner: "/partner/help",

  // USER PATH
  homeUser: "/user/home",
  //---setting
  settingUser: "/user/setting",
  myAccountUser: "/user/setting/my_account",
  changePasswordUser: "/user/setting/change_password",
  changeLanguageUser: "/user/setting/change_language",
  termsUser: "/user/setting/terms_of_use",
  privacyPolicyUser: "/user/setting/privacy_policy",

  //---banner
  calendarUser: "/user/calendar",
  calendarUserDetail: "user/calendar/detail",
  promotionUser: "/user/promotion",
  promotionDetailUser: "/user/promotion_detail/:id",
  familyInforUser: "/user/family_infor",
  addFamilyInforUser: "/user/family_infor/add",
  updateFamilyInforUser: "/user/family_infor/update",
  theJobsUser: "/user/jobs",
  jobDetailUser: "/user/job_detail/:job_id",
  freeCourseUser: "/user/free_course",
  healthKnowledgeUser: "/user/health_knowledge",
  notificationUser: "/user/noti",
  notificationUserDetail: "/user/noti/detail",
  statisticUser: "/user/statistic",
  aboutUser: "/user/about",
  helpUser: "/user/help",
  //booking
  booking: "/user/booking",
  bookingMap: "/user/booking/map",
  bookingType: "/user/booking/type",
  selectPayment: "/user/booking/payment",
  selectUser: "/user/booking/user",
  serviceConfirm: "/user/booking/confirm",
  transferInfo: "/user/booking/transfer_info",

  //---service
  listServiceByCategory: "/user/list_service/:category_id",
  serviceDetail: "/user/service_detail/:service_id",

  // PUBLIC PATH
  //user auth
  login: "/user/login",
  register: "/user/register",
  terms: "/terms_of_use",
  resetPassword: "/user/reset_password",
  createPassword: "/user/create_password",
  createPasswordPartner: "/partner/create_password",
  //partner auth
  loginPartner: "/partner/login",
  registerPartner: "/partner/register",
};

export default paths;
