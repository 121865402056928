import { useEffect, useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
// import icon_back from "../../../../assets/icons/icon_back.png";
import paths from "../../../../config";
import { addBankPartner, getSalaryDataPartner } from "../../../../redux/Slices/partnerSlice";
import { FormAddBank } from "../../../../types/common";
import local, { getLocalStorage } from "../../../../helper/localStorage";
import { useTranslation } from "react-i18next";
import HeaderSevice from "../../../../components/HeaderService";

const BankInfo = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [bankSelected, setBankSelected] = useState<boolean>(false);
  const [api, showPopup] = notification.useNotification();
  const bankInfo = useAppSelector((state) => state.partner.paymentData?.bank_account);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.partner.loadingAddBank);
  const data_bank = local.StorageGetSecure("data_bank");
  const { t, i18n } = useTranslation(["wallet", "common"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    !bankInfo && dispatch(getSalaryDataPartner());
  }, [dispatch, bankInfo]);

  useEffect(() => {
    bankInfo &&
      form.setFieldsValue({
        bank: bankInfo
          ? `${bankInfo.bank}, ${bankInfo.city}, ${bankInfo.bank_branch}`
          : `${data_bank?.bank}, ${data_bank?.city}, ${data_bank?.bank_branch}`,
        owner_name: bankInfo ? bankInfo.owner_name : "",
        bank_account_number: bankInfo ? bankInfo.bank_account_number : "",
      });
  }, [bankInfo, data_bank, form]);

  //   const handleFieldChange = (changedFields: any, allFields: any) => {
  //     if (allFields.bank) {
  //       setBankSelected(true);
  //     }
  //   };

  const onFinish = (values: any) => {
    const data: FormAddBank = {
      owner_name: values.owner_name,
      bank: data_bank?.bank,
      city: data_bank?.city,
      bank_branch: data_bank?.bank_branch,
      bank_account_number: values.bank_account_number,
    };
    if (!data_bank?.bank || !data_bank?.city || !data_bank?.bank_branch) {
      form.setFields([{ name: "bank", errors: [t("common:yeu_cau_khong_de_trong")] }]);
    } else if (!bankInfo) {
      try {
        // console.log("data", data);
        dispatch(addBankPartner(data)).then((resultAction: any) => {
          const payload = resultAction.payload as { message: string };
          if (addBankPartner.fulfilled.match(resultAction)) {
            alertSuccess(payload.message || "Thêm mới tài khoản nhận tiền thành công !");
            setTimeout(() => {
              navigate("/partner/wallet");
              local.StorageRemoveSecure("data_bank");
            }, 1500);
          } else if (addBankPartner.rejected.match(resultAction)) {
            alertFail(payload.message || "Thêm mới tài khoản nhận tiền thất bại, vui lòng thử lại!");
          }
        });
      } catch (error: any) {
        // console.error(error);
        alertFail(error.message);
      }
    }
  };

  //   const contentPopover = (
  //     <Link to={paths.selectBank} className="bank-info-form-item-link">
  //       <Button>Chọn lại ngân hàng</Button>
  //     </Link>
  //   );

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };

  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  return (
    <div className="wrapper bank-info">
      {showPopup}
      <HeaderSevice text={t("thong_tin_ngan_hang")} />
      {/* <div className="header">
        <button className="header-back-button" onClick={() => navigate("/partner/wallet")}>
          <div className="header-back-icon">
            <img src={icon_back} alt="Back" />
          </div>
        </button>
        <span className="header-text">Thông tin ngân hàng</span>
      </div> */}
      <div className="bank-info content-container">
        <h1 className="bank-info-title">{t("tai_khoan_nhan_tien")}</h1>
        <div className="bank-info-form">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className="bank-info-form"
            // onValuesChange={(changedValues, allFields) => handleFieldChange(changedValues, allFields)}
            initialValues={{
              bank: bankInfo
                ? `${bankInfo.bank}, ${bankInfo.city}, ${bankInfo.bank_branch}`
                : `${data_bank?.bank}, ${data_bank?.city}, ${data_bank?.bank_branch}`,
              owner_name: bankInfo ? bankInfo.owner_name : "",
              bank_account_number: bankInfo ? bankInfo.bank_account_number : "",
            }}
          >
            <div className="bank-info-form-item">
              {bankInfo ? (
                <Form.Item label={t("ngan_hang_nhan_thanh_toan")} name="bank">
                  <Input disabled className="bank-info-form-item-input" />
                </Form.Item>
              ) : (
                <Form.Item required label={t("ngan_hang_nhan_thanh_toan")} name="bank">
                  <Link to={paths.selectBank} className="bank-info-form-item-link">
                    {data_bank
                      ? `${data_bank?.bank || ""}, ${data_bank?.city || ""}, ${data_bank?.bank_branch || ""}`
                      : t("chon_ngan_hang")}
                  </Link>
                </Form.Item>
              )}
            </div>
            <div className="bank-info-form-item">
              <Form.Item
                label={t("ten_chu_tai_khoan")}
                name="owner_name"
                rules={[
                  { required: true, message: t("common:yeu_cau_khong_de_trong") },
                  //   {
                  //     validator: () => {
                  //       if (!bankSelected) {
                  //         return Promise.reject("Vui lòng chọn ngân hàng trước khi nhập tên chủ tài khoản!");
                  //       }
                  //       return Promise.resolve();
                  //     },
                  //   },
                ]}
              >
                <Input
                  placeholder={t("ten_chu_tai_khoan")}
                  disabled={bankInfo}
                  className="bank-info-form-item-input"
                  onInput={(e) => {
                    e.currentTarget.value = e.currentTarget.value
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toUpperCase();
                    e.currentTarget.value.trim() !== "" ? setBankSelected(true) : setBankSelected(false);
                  }}
                />
              </Form.Item>
            </div>
            <div className="bank-info-form-item">
              <Form.Item
                label={t("so_tai_khoan")}
                name="bank_account_number"
                rules={[
                  { required: true, message: t("common:yeu_cau_khong_de_trong") },
                  //   {
                  //     validator: () => {
                  //       if (!bankSelected) {
                  //         return Promise.reject("Vui lòng chọn ngân hàng trước khi nhập số tài khoản!");
                  //       }
                  //       return Promise.resolve();
                  //     },
                  //   },
                ]}
              >
                <Input placeholder={t("so_tai_khoan")} disabled={bankInfo} className="bank-info-form-item-input" />
              </Form.Item>
            </div>
            <div className="bank-info-form-item">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={bankInfo || !bankSelected}
                  className="btn-submit primary"
                >
                  {t("luu")}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BankInfo;
